import { MbscModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MatIconRegistry } from "@angular/material/icon";
import { HttpClient, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
//import { ErrorInterceptor } from './shared/services/error.interceptor';
import { CommonModule } from '@angular/common';
import { SoffSharedModule } from './shared/shared.module';
import { SoffCoreModule } from './shared/core.module';

import { AppComponent } from './app.component';

// services
import { DsmApiCall } from './shared/services/dsm-api-call.service';
import { AuthServiceWrapper } from './shared/services/auth.service';

// LOCALES based on languages available in CLAAS ID
import localeCzech from '@angular/common/locales/cs'
import localeDenmark from '@angular/common/locales/da'
import localeGermany from '@angular/common/locales/de';
import localeSpain from '@angular/common/locales/es';
import localeEstonia from '@angular/common/locales/et';
import localeEnGb from '@angular/common/locales/en-GB';
import localeFrance from '@angular/common/locales/fr';
import localeItaly from '@angular/common/locales/it';
import localeLatvia from '@angular/common/locales/lv';
import localeLithuania from '@angular/common/locales/lt';
import localeDutch from '@angular/common/locales/nl';
import localeNorway from '@angular/common/locales/nb'; // claas id returns 'no'
import localeRomania from '@angular/common/locales/ro';
import localePoland from '@angular/common/locales/pl';
import localePortugal from '@angular/common/locales/pt';
import localeSlovakia from '@angular/common/locales/sk';
import localeSweden from '@angular/common/locales/sv';
import localeUkraine from '@angular/common/locales/uk';


import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SoffAccountModule } from './features/account/account.module';
import { SoffAccountRoutingModule } from './features/account/account.routing';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

//registerLocaleData(localeCzech);
registerLocaleData(localeDenmark);
registerLocaleData(localeGermany);
registerLocaleData(localeSpain);
registerLocaleData(localeEstonia);
registerLocaleData(localeEnGb);
registerLocaleData(localeFrance);
registerLocaleData(localeItaly);
registerLocaleData(localeLatvia);
registerLocaleData(localeLithuania);
registerLocaleData(localeDutch);
registerLocaleData(localeNorway);
registerLocaleData(localeRomania);
registerLocaleData(localePoland);
registerLocaleData(localePortugal);
registerLocaleData(localeSlovakia);
registerLocaleData(localeSweden);
registerLocaleData(localeUkraine);

const PROVIDERS = [
  // SERVICES
  DsmApiCall,
  AuthServiceWrapper,
//  AccountService,
  { provide: localeCzech, useValue: "cs" },
  { provide: localeDenmark, useValue: "da" },
  { provide: localeGermany, useValue: "de" },
  { provide: localeSpain, useValue: "es" },
  { provide: localeEstonia, useValue: "et" },
  { provide: localeEnGb, useValue: "en-GB" },
  { provide: localeFrance, useValue: "fr" },
  { provide: localeItaly, useValue: "it" },
  { provide: localeLatvia, useValue: "lv" },
  { provide: localeLithuania, useValue: "lt" },
  { provide: localeDutch, useValue: "nl" },
  { provide: localeNorway, useValue: "no" },
  { provide: localeRomania, useValue: "ro" },
  { provide: localePoland, useValue: "pl" },
  { provide: localePortugal, useValue: "pt" },
  { provide: localeSlovakia, useValue: "sk" },
  { provide: localeSweden, useValue: "sv" },
  { provide: localeUkraine, useValue: "uk" },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
];

import config from './app.config';
import { SoffSearchModule } from './features/search/search.module';
import { SoffSearchRoutingModule } from './features/search/search.routing';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgChartsModule } from 'ng2-charts';
//
import { SoffMachineBaseModule } from './features/machines/machines.module.base';
import { SoffMachinesRoutingModule } from './features/machines/machines.routing';
import { SoffFieldServiceBaseModule } from './features/field-service/field-service.base.module';
import { SoffFieldServiceRoutingModule } from './features/field-service/field-service.routing';
import { SoffMachineComponentsModule } from './features/machines/machines.module.components';
import { SoffMachinePagesModule } from './features/machines/machines.module.pages';
//
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SoffEmployeeBaseModule } from './features/employees/employees.module.base';
import { SoffEmployeeRoutingModule } from './features/employees/employees.routing';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, [
      {prefix: './assets/i18n/', suffix: '.json'},
      {prefix: './assets/i18n/intro/', suffix: '.json'},
      {prefix: './assets/i18n/telemetry/', suffix: '.json'}
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [  
    MbscModule,   
    FormsModule,  
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    SoffSharedModule,
    SoffCoreModule,
    SoffAccountModule,
    SoffAccountRoutingModule,
    SoffSearchModule,
    SoffSearchRoutingModule,
    
    OktaAuthModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLanguage,
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
      }
    }),
    NgChartsModule,
  ],
  exports: [],
  providers: [
    PROVIDERS, MatIconRegistry,
{ 
  provide: OKTA_CONFIG, 
  useFactory: () => {
    const oktaAuth = new OktaAuth(config.oidc);
    return {
      oktaAuth,
    }
  }
}

/*    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },*/
],
  bootstrap: [AppComponent]
})
export class AppModule { }


