import { Component, OnInit, OnDestroy, ViewChild, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, Subscription, tap } from 'rxjs';

import { AuthServiceWrapper } from './shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import * as RootState from './shared/state';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, ResolveEnd, Router } from '@angular/router';

import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { SideNavigationItem, SideNavigationItems } from './shared/components/navbar/side-navbar.source';

import { LayoutActions, LayoutReducer, LayoutSelectors } from './shared/state/layout';
import { BaseActions } from './shared/state/base';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import {OktaAuth, AuthState, IDToken } from '@okta/okta-auth-js';
//import { AccountActions, AccountSelectors } from './features/account/state';
import * as versioning from './shared/changelog/version';
import { BreadCrumbData } from './shared/models/layout.model';
import { AccountActions } from './shared/state/account';
import { InternationalizationService } from './shared/services/internationalization.service';
import { AccessGuard } from './shared/guard/access.guard';
import { LayoutService } from './shared/services/layout.service';
import { imprintLinks } from './shared/components/navbar/imprint';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{

  @ViewChild('dsmSideNavigation') public drawer: MatDrawer;
  public sideNavOpened$: Observable<boolean> = this.store.select(LayoutSelectors.getSideNavOpened);
  public sideNavigationItems: SideNavigationItem[] = SideNavigationItems;
  
  public dsmVersion: string = versioning.dsmVersion;

  public currentURL: string;

  isExpanded: boolean = false;

  private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();
  
  public breadCrumbData$: Observable<BreadCrumbData> = this.layoutStore.select(LayoutSelectors.getBreadCrumbData);
  public breadCrumbData: BreadCrumbData;
  public breadCrumbSub: Subscription;

  public isLoginPage = false;
  public isOnIntroPage = false;
  public isOnLegalPage = false;

  constructor(
  private activatedRoute: ActivatedRoute,
  private auth: AuthServiceWrapper,
  private translator: TranslateService,
  private _layoutService: LayoutService,
  private internationalizationService: InternationalizationService,
  private store: Store <RootState.State>,
  private iconRegistry: MatIconRegistry,
  private sanitizer:DomSanitizer,
  private _router: Router,  
  private layoutStore: Store<LayoutReducer.State>,
  private change: ChangeDetectorRef,
  public accessGuard: AccessGuard,
  public authStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth){
  }


  @HostListener('window:load')
  private onInitalPageLoad(): void {
    setTimeout(() => {
      this.authStateService.authState$.pipe(
        filter((s: AuthState) => !!s),
      ).subscribe((s: AuthState) => {
        if (!!s.isAuthenticated) {
          this.store.dispatch(new BaseActions.RehydrateApplicationState());
        } else {
          this.store.dispatch(new AccountActions.SessionLogout());
        }

        this.isLoggedInSubject.next(s.isAuthenticated);
      });

    }, 200);
  }

  /**
   * 
   * @returns 
   *
  private deriveLegalLanguage(): string {
    const userLanguage: string = this.internationalizationService.getUserLanguage();
    if (this.legalLanguages.filter(l => l === userLanguage).length > 0) {
      return userLanguage;
    } else {
      // default
      return "en";
    }
  }*/

  ngOnInit(){
    //this.legalLanguage = this.deriveLegalLanguage();
    this.sideNavigationItems.forEach((item: SideNavigationItem) => {
      if (item.labelI18nKey === 'global.navigation.disclaimer') {
        switch(this.internationalizationService.getUserLanguage()) {
          case 'de': item.routerLink = '/app/disclaimer/de';
            break;
          case 'en': item.routerLink = '/app/disclaimer/en';
            break;
          case 'es': item.routerLink = '/app/disclaimer/es';
            break;
          case 'fr': item.routerLink = '/app/disclaimer/fr';
            break;
          case 'ru': item.routerLink = '/app/disclaimer/ru';
            break;
          case 'uk': item.routerLink = '/app/disclaimer/uk';
            break;
        }
      }

      if (item.labelI18nKey === 'global.navigation.privacy') {
        switch(this.internationalizationService.getUserLanguage()) {
          case 'de': item.routerLink = '/app/privacy/de';
            break;
          case 'en': item.routerLink = '/app/privacy/en';
            break;
          case 'es': item.routerLink = '/app/privacy/es';
            break;
          case 'fr': item.routerLink = '/app/privacy/fr';
            break;
          case 'ru': item.routerLink = '/app/privacy/ru';
            break;
          case 'uk': item.routerLink = '/app/privacy/uk';
            break;
        }
      }

      this.iconRegistry.addSvgIconInNamespace('base', item.icon, this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sidenav/' + item.icon));
    });
    this.iconRegistry.addSvgIconInNamespace('base', 'imprint', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sidenav/paragraph.svg'));

    this.breadCrumbSub = this.breadCrumbData$.subscribe((data) => {
      this.breadCrumbData = data;
      this.change.detectChanges();
    });

    this._router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.currentURL = val.url;

        if (val.url.startsWith('/intro')) {
          this.isOnIntroPage = true;
          this.isLoginPage = false;
          this.isOnLegalPage = false;
        }else if(val.url.startsWith('/legal')) {
          this.isOnLegalPage = true;
          this.isLoginPage = false;
          this.isOnIntroPage = false;
        }else if(val.url.startsWith('/app/account/login')) {
          this.isLoginPage = true;
          this.isOnLegalPage = false;
          this.isOnIntroPage = false;
        }else {
          this.isLoginPage = false;
          this.isOnIntroPage = false;
          this.isOnLegalPage = false;
        }
      }
    });
  }

  showTermsOfUseBreadcrumb() {
    let targetURL = ["app"]
    this.layoutStore.dispatch(new LayoutActions.ShowBreadCrumb({
      targetLink: targetURL,
      targetNameI18n: 'TermsOfUse',
      resourceName: 'Service Office'
    }));
  }

  showPrivacyBreadcrumb() {
    let targetURL = ["app"];
    this.layoutStore.dispatch(new LayoutActions.ShowBreadCrumb({
      targetLink: targetURL,
      targetNameI18n: 'Privacy',
      resourceName: 'Service Office'
    }));
  }

  ngOnDestroy(){
//    this.userProfile$.unsubscribe();
    this.breadCrumbSub?.unsubscribe();
  }

  
  getActionFromNavbar(event: any){ this.isExpanded = event; }


  openApp(app:any){
    if(app.href) { this._router?.navigate([app.href]); }
    else if (app.link) { window.location.href = app.link }
  };


  navigateToImprint(): void {
    let link: string = imprintLinks[this.internationalizationService.getUserLanguage()];
    if (!link) link = imprintLinks['en'];
    window.open(link, '_blank');
  }


}
