export const imprintLinks: {[key: string] : string} = {
    "fr": "https://www.claas.com/fr-fr/a-propos-de-claas/droit/mentions-legales",
    "de": "https://www.claas.com/de-de/ueber-claas/recht/impressum",
    "zh": "https://www.claas.com/zh-cn/about-claas/legal-topics/imprint",
    "en": "https://www.claas.com/en-gb/about-claas/legal/legal-notice",
    "da": "https://www.claas.com/da-dk/om-claas/juridiske-emner/kolofon",
    "it": "https://www.claas.com/it-it/lazienda-claas/legale/colophon",
    "hr": "https://www.claas.com/hr-hr/o-claas-u/recht/impresum",
    "lt": "https://www.claas.com/lt-lt/apie-claas/teisines-temos/teisine-informacija",
    "no": "https://www.claas.com/no-no/om-claas/rett/selskapsoversikt",
    "pl": "https://www.claas.com/pl-pl/o-firmie-CLAAS/prawo/metryczka",
    "pt": "https://www.claas.com/pt-pt/sobre-claas/topicos-juridicos/informacao-legal",
    "ro": "https://www.claas.com/ro-ro/despre-claas/drept/cadru-juridic",
    "es": "https://www.claas.com/es-es/sobre-claas/derecho/aviso-legal",
    "tr": "https://www.claas.com/tr-tr/claas-hakkinda/hukuki-konular/kuenye",
    "uk": "https://www.claas.com/uk-ua/pro-claas/pravo/imprint",
    "sv": "https://www.claas.com/sv-se/om-claas/juridiska-fragor/saekerhet-cookies",
    "bg": "https://www.claas.com/bg-bg/za-claas/pravny-temy/redaktorsko-kare",
    "ru": "https://www.claas.com/ru-ru/o-kompanii-claas/pravovaya-informatsiya/vykhodnyye-dannyye",
    "ja": "https://www.claas.com/ja-jp/about-claas/legal/imprint",
    "th": "https://www.claas.com/th-th/kei-yw-kab-claas/h-awk-hx-thang-kdh-may/khat-haelng-karn-thang-kdh-may"
};