import { ActionReducerMap } from '@ngrx/store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */


import { LayoutReducer, LayoutEffects } from './layout';
import { AccountReducers, AccountEffects } from './account';
import { NotificationEffects, NotificationReducer } from './notification';

import { CUSTOMER_EFFECTS, CustomerReducer } from 'src/app/features/customers/state';
import { EMPLOYEE_EFFECTS, EmployeeReducer } from 'src/app/features/employees/state';
import { FIELDSERVICE_EFFECTS, FieldServiceReducer } from 'src/app/features/field-service/state';
import { INCOMING_TOPICS_EFFECTS, IncomingTopicsReducer} from 'src/app/features/incoming-topics/state';
import { MACHINES_EFFECTS, MachineReducer} from 'src/app/features/machines/state';
import { SERVICEVAN_EFFECTS, ServiceVanReducer } from 'src/app/features/servicevans/state';

export type StateError = Error | string | null;

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  rsAccount: AccountReducers.AccountState;
  rsLayout: LayoutReducer.State;
  rsNotifications: NotificationReducer.State;
  // TODO make modularization work again :) Only put into the
  // root store in order to make feature selectors working 
  // again  
  // fsMachines: MachineReducer.MachineState;
  fsCustomers: CustomerReducer.CustomerState;
  fsEmployees: EmployeeReducer.EmployeeState;
  fsFieldService: FieldServiceReducer.FieldServiceState;
  fsIncomingTopics: IncomingTopicsReducer.IncomingTopicsState;
  fsMachines: MachineReducer.MachineState;
  fsServiceVans: ServiceVanReducer.ServiceVanState;

}

/**
 * Re-export all of our shared Effects.
 *
 * Registered in the `core` ngModule
 */
export const effects = [
  AccountEffects,
  LayoutEffects,
  NotificationEffects,

  // ,
  // EMPLOYEE_EFFECTS,
  // FIELDSERVICE_EFFECTS,
  // INCOMING_TOPICS_EFFECTS,
  // MACHINES_EFFECTS,
  // SERVICEVAN_EFFECTS,
]
.concat(CUSTOMER_EFFECTS)
.concat(EMPLOYEE_EFFECTS)
.concat(FIELDSERVICE_EFFECTS)
.concat(INCOMING_TOPICS_EFFECTS)
.concat(MACHINES_EFFECTS)
.concat(SERVICEVAN_EFFECTS);

 export const reducers: ActionReducerMap<State, any> = {
  rsAccount: AccountReducers.reducer,
  rsLayout: LayoutReducer.reducer,
  rsNotifications: NotificationReducer.reducer,

  fsCustomers: CustomerReducer.reducer,
  fsEmployees: EmployeeReducer.reducer,
  fsFieldService: FieldServiceReducer.reducer,
  fsIncomingTopics: IncomingTopicsReducer.reducer,
  fsMachines: MachineReducer.reducer,
  fsServiceVans: ServiceVanReducer.reducer,
 };




