import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './shared/components/callback/callback.component';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { InterceptorService } from './shared/services/interceptor.service';
import { AppAuthGuard } from './shared/guard/app-auth.guard';
import { AccessGuard } from './shared/guard/access.guard';
import { DsmLicenseEnum } from './shared/models/account/workshop.sto';
import { SoffWorkOrderPermission } from './shared/models/account/auth.model.sto';

const routes: Routes = [
  { path: "callback", component: CallbackComponent },

  { path: '', redirectTo: 'intro', pathMatch: 'full' },

  { path: "app/account",
      loadChildren: () => import ('./features/account/account.module').then(m => m.SoffAccountModule )
  },


    // Disclaimer
    { 
      path: "legal/terms-of-use",
      loadChildren: () => import ('./features/legal-terms-of-use/terms-of-use.module').then(m => m.SoffTermsOfUseModule )
    },
    
    // Privacy
    { 
      path: "legal/privacy",
      loadChildren: () => import ('./features/legal-privacy/privacy.module').then(m => m.SoffPrivacyModule )
    },


  { path: 'app', canActivateChild: [AppAuthGuard], children: [

    { path: '', redirectTo: 'incoming-topics', pathMatch: 'full' },

    // Feature: customers 
    { 
      path: "customers",
      loadChildren: () => import ('./features/customers/customers.module.pages').then(m => m.SoffCustomerPagesModule )
    },

    // Feature: employees
    { 
      path: "employees",
      loadChildren: () => import ('./features/employees/employees.module.assemblies').then(m => m.SoffEmployeeAssembliesModule ),
      canActivateChild: [AccessGuard],
      data: { dsmLicenseNeeded: [ DsmLicenseEnum.PLUS, DsmLicenseEnum.PRO ], 
              permissionNeeded: [ SoffWorkOrderPermission.WORKORDER_CREATE ], // only usable for Foremen
              isProductionReady: true }
    },

    // Feature: incoming-topics
    { 
      path: "incoming-topics",
      loadChildren: () => import ('./features/incoming-topics/incoming-topics.pages.module').then(m => m.SoffIncomingTopicPagesModule )
    },

    // Feature: field-service
    { 
      path: "field-service",
      loadChildren: () => import ('./features/field-service/field-service.assemblies.module').then(m => m.SoffFieldServiceAssembliesModule ),
      canActivateChild: [AccessGuard],
      data: { dsmLicenseNeeded: [ DsmLicenseEnum.PLUS, DsmLicenseEnum.PRO ], 
              permissionNeeded: [ SoffWorkOrderPermission.WORKORDER_CREATE ], // only usable for Foremen
              isProductionReady: true }
    },
    
    // Feature: machine
    { 
      path: "machines",
      loadChildren: () => import ('./features/machines/machines.module.pages').then(m => m.SoffMachinePagesModule ),
      canActivateChild: [AppAuthGuard]
    },

    // Feature: search
    { 
      path: "search",
      loadChildren: () => import ('./features/search/search.module').then(m => m.SoffSearchModule ),
      canActivateChild: [AppAuthGuard]
    },

    // Feature: servicevan
    { 
      path: "servicevans",
      loadChildren: () => import ('./features/servicevans/servicevans.module.pages').then(m => m.SoffServiceVansPagesModule ),
      canActivateChild: [AppAuthGuard]
    },

    // Feature: technical-documentation
    { 
      path: "technical-documentation",
      loadChildren: () => import ('./features/technical-documentation/technical-documentation.module').then(m => m.SoffTechnicalDocumentationModule ),
      canActivateChild: [AppAuthGuard]
    },

  ]},

  { path: 'intro', canActivateChild: [AppAuthGuard], children: [

    // Intro: start 
    { 
      path: "",
      loadChildren: () => import ('./intro/intro.module').then(m => m.SoffIntroModule )
    },
  ]},

  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
/*    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }*/
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor() {}
    
}
